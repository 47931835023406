import { FC } from "react";

import Footer from "../../atoms/Footer/Footer";
import CallToAction from "../../molecules/CallToAction/CallToAction";
import FeaturesText from "../../molecules/FeaturesText/FeaturesText";
import HeroImage from "../../molecules/HeroImage/HeroImage";
import LandingNavBar from "../../molecules/LandingNavBar/LandingNavBar";
import ProductShowcase from "../../molecules/ProductShowcase/ProductShowcase";

type HomeProps = {};

const Home: FC<HomeProps> = () => (
  <div className="w-100">
    <LandingNavBar />
    <HeroImage />
    <ProductShowcase />
    <FeaturesText />
    <CallToAction />
    <Footer />
  </div>
);

export default Home;
