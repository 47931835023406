import React, { FC } from "react";

import Button from "../../atoms/Button/Button";

type HeroImageProps = {};

const HeroImage: FC<HeroImageProps> = () => (
  <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden md:my-16">
    <div className="container mx-auto px-6 flex relative py-16">
      <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
        <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12" />
        <h1 className="font-bebas-neue uppercase text-5xl sm:text-7xl font-black flex flex-col leading-none dark:text-white text-gray-800">
          Webflow plugins that increase
          <span className="text-6xl sm:text-8xl">sales</span>
        </h1>
        <p className="text-sm sm:text-lg text-gray-700 mt-4 dark:text-white">
          Increase retention and sales. No code required. With our plugins you
          will retain your customers for longer and leverage the art of
          upselling.
        </p>
        <div className="mt-8 sm:self-start w-full sm:w-60">
          <a href={`${process.env.NEXT_PUBLIC_SHOPIRISE_APP_URL}/signup`}>
            <Button>Try for free</Button>
          </a>
        </div>
      </div>
      <div className="hidden sm:flex justify-center items-center sm:w-1/3 lg:w-3/5 relative">
        <img
          src="/images/ecommerce.svg"
          alt="Hero"
          className="max-w-sm md:max-w-xl m-auto"
        />
      </div>
    </div>
  </div>
);

export default HeroImage;
