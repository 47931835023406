import Link from "next/link";
import React, { FC } from "react";

import Button from "../../atoms/Button/Button";

type ProductShowcaseProps = {};

const ProductShowcase: FC<ProductShowcaseProps> = () => (
  <div className="max-w-screen-xl mx-auto bg-white dark:bg-gray-800 p-4 mt-12 flex flex-col items-center">
    <h2 className="mt-2 text-2xl leading-8 font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:leading-9">
      Our plugins
    </h2>
    <p className="mt-4 max-w-2xl text-lg leading-6 text-gray-500 dark:text-gray-300 text-center">
      Delight your users and create the most pleasant experience for people
      visiting your Webflow e-commerce.
    </p>
    <div className="mt-16 flex flex-wrap md:flex-nowrap gap-8">
      <Link href="/products/frequently-bought-together">
        <div className="md:w-1/2 w-full overflow-hidden pb-6 hover:bg-blue-50 hover:shadow-md rounded-md cursor-pointer p-4">
          <h3 className="text-2xl leading-8 font-extrabold text-gray-900 dark:text-white text-center">
            Frequently bought together plugin
          </h3>
          <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300 text-center">
            Increase your sales with just a simple integration, no code
            required. Upsell your products and drive your online shop's revenue
            up with this plugin.
          </p>
          <img
            src="/images/fbt-screenshot.jpg"
            loading="lazy"
            alt="product one"
            className="mx-auto rounded-md shadow-md mt-8 w-3/4"
          />
          <div className="mt-8 flex justify-center">
            <Link href="/products/frequently-bought-together">
              <a>
                <Button className="w-auto">View product</Button>
              </a>
            </Link>
          </div>
        </div>
      </Link>
      <Link href="/products/recommendations">
        <div className="md:w-1/2 w-full overflow-hidden pb-6 hover:bg-blue-50 hover:shadow-md rounded-md cursor-pointer p-4">
          <h3 className="text-2xl leading-8 font-extrabold text-gray-900 dark:text-white text-center">
            Recommendations plugin
          </h3>
          <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300 text-center">
            Improve retention and sales by recommending similar products on your
            product pages. Recommendations increase engagement which results in
            more sales.
          </p>
          <img
            src="/images/recommendations.jpg"
            loading="lazy"
            alt="product one"
            className="mx-auto rounded-md shadow-md mt-8 w-3/4"
          />
          <div className="mt-8 flex justify-center">
            <Link href="/products/recommendations">
              <a>
                <Button className="w-auto">View product</Button>
              </a>
            </Link>
          </div>
        </div>
      </Link>
    </div>
  </div>
);

export default ProductShowcase;
