import React, { FC } from "react";
import { Code, Repeat, Zap } from "react-feather";

import Heading from "../../atoms/Heading/Heading";

type FeaturesTextProps = {};

const FeaturesText: FC<FeaturesTextProps> = () => (
  <div className="max-w-screen-xl mx-auto relative bg-white dark:bg-gray-800 p-4">
    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
      <div className="lg:col-start-2 md:pl-20">
        <Heading isCentered={false}>All your shop needs</Heading>
        <p className="mt-4 max-w-4xl text-base leading-6 text-gray-500 dark:text-gray-300">
          With Shopirise you will get all the tools your online shop needs to
          drive engagement and sales up.
        </p>
        <ul className="mt-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <Code />
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                  No coding required
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                  Just like in Webflow, you don't need any coding skills to get
                  started.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <Zap />
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                  Top-notch technology underneath
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                  We take care of all the technology under the hood and provide
                  you with the most effective recommendation algorithms so that
                  you can focus on what matters to you: your business.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                  <Repeat />
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                  Seamless integration
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                  Integrating Webflow and Shopirise is as easy as pie. In a few
                  minutes you can get your plugins up and running without
                  needing any technical support.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
        <img
          src="/images/growth.svg"
          alt="illustration"
          loading="lazy"
          className="relative mx-auto w-auto"
        />
      </div>
    </div>
  </div>
);

export default FeaturesText;
