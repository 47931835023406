import React, { FC } from "react";

import Button from "../../atoms/Button/Button";

type CallToActionProps = {};

const CallToAction: FC<CallToActionProps> = () => (
  <div className="bg-white dark:bg-gray-800 ">
    <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
      <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
        <span className="block">Do you want to start selling more?</span>
        <span className="block text-indigo-500">
          You are just a few clicks away
        </span>
      </h2>
      <p className="text-xl mt-4 max-w-md mx-auto text-gray-600">
        Try out our plugins for free. Get a 14-day trial of our paid tiers.
      </p>
      <div className="lg:mt-0 lg:flex-shrink-0">
        <div className="mt-12 inline-flex">
          <a href={`${process.env.NEXT_PUBLIC_SHOPIRISE_APP_URL}/signup`}>
            <Button>Get started</Button>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default CallToAction;
