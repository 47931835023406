import cx from "classnames";
import React, { FC, ReactNode } from "react";

type HeadingProps = {
  isCentered?: boolean;
  className?: string;
  children: ReactNode;
};

const Heading: FC<HeadingProps> = ({
  children,
  className,
  isCentered = true,
}) => (
  <h4
    className={cx(
      "mt-2 text-2xl leading-8 font-extrabold text-gray-900 dark:text-white sm:text-3xl sm:leading-9",
      {
        "text-center": isCentered,
      },
      className
    )}
  >
    {children}
  </h4>
);

export default Heading;
